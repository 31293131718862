import React from "react";
import {FormattedMessage} from "react-intl";
import Button, {ButtonColor} from "./button";

const PuzzleDetails = () => {
  return (
    <>
      <p className="rfs-large font-weight-bold mb-2 mt-4">
        <FormattedMessage id="section.puzzle.title" />
      </p>
      <p className="rfs-large">
        <FormattedMessage id="section.puzzle.description" />
      </p>
    </>
  );
};

export default PuzzleDetails;
